import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, EndpointDisplay, KMCurve, PatientProfile, ReferencesBlock, NCCNcallout, TextBlock, ForestPlot} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import HRTNBC from '../../components/StudyDesign/__study-design/high-risk-tnbc';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-522 - Clinical Trial Results | HCP`,
    keywords: `keynote 522, triple negative breast cancer clinical trial results`,
    description: `Health care professionals may view findings from KEYNOTE-522, a clinical trial for patients with highrisk early-stage triple-negative breast cancer (TNBC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠522","description":"Clinical Findings from KEYNOTE⁠-⁠522","trialDesign":"Randomized, multicenter, double-blind, placebo-controlled trial conducted in 1,174 patients with newly diagnosed, previously untreated high-risk early-stage TNBC"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/early-stage-triple-negative-breast-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 27;
const jobCode = jobCodes[26].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced TNBC', url: '/efficacy/triple-negative-breast-cancer/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Study Design for KEYNOTE&#8288;-&#8288;522'},
    {label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;522'},
    {label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)'},
    {label: 'Appropriate Patients'}
]

const pcrKeySecondaryCalloutFootnotes = [
    {
        label: "c.",
        text: "Based on the entire intention-to-treat population N=1,174 patients."
    }
    ,{
        label: "d.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    ,{
        label: "e.",
        text: "Based on a prespecificed pCR interim analysis in patients (n=602), the pCR rate difference was statistically significant (<i>P</i>=0.00055 compared to a significance level of 0.003)."
    },
    {
        label: "f.",
        text: "Based on Miettinen and Nurminen method stratified by nodal status, tumor size, and choice of carboplatin."
    }
]

const keyChemoFootnotes = [
    {
        label: "g.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    {
        label: "h.",
        text: "HR based on stratified Cox regression model."
    },
    {
        label: "i.",
        text: "<i>P</i>-value based on a prespecified EFS interim analysis (compared to a significance level of 0.0052)."
    },
    {
        label: "j.",
        text: "<i>P</i>-value based on log-rank test stratified by nodal status, tumor size, and choice of carboplatin."
    },
]

const NCCNcalloutData = {
    showBadge: false,
    bodyCopy: `
        <h4>Pembrolizumab (KEYTRUDA): An NCCN Guideline®-recommended treatment option for eligible patients with high-risk early-stage TNBC<sup>8,t</sup></h4>
        <h5>Category 2A and NCCN Preferred Option</h5>
        <p><b>Preoperative pembrolizumab (KEYTRUDA) in combination with carboplatin + paclitaxel, followed by preoperative pembrolizumab in combination with cyclophosphamide + doxorubicin or epirubicin, followed by adjuvant pembrolizumab<sup>8</sup></b></p>
    `,
};

const NCCNcalloutFootnotes = [
    {
        label: 't.',
        text: 'High-risk criteria include Stage II-III TNBC. The use of adjuvant pembrolizumab (category 2A) may be individualized.<sup>8</sup>'
    },
];

const NCCNcalloutDefinitions = `
    <p>Preferred intervention = Intervention that is based on superior efficacy, safety, and evidence; and, when appropriate, affordability.<sup>8</sup></p>
    <p>Category 2A = Based upon lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup>8</sup></p>
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    <p>NCCN® = National Comprehensive Cancer Network®.</p>
`;

const NCCNcalloutReferences = [
    {
        label: '8.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Breast Cancer v4.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed March 24, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org.'
    }
];

const patientProfileCheyanne = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-cheyanne.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Cheyanne",
    diagnosis: "Clinical Prognostic Stage IIB TNBC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of breast cancer',
            emphasize: false,
        },
        {
            key: 'Physical exam',
            value: 'Palpable mass in left breast',
            emphasize: false,
        },
        {
            key: 'Mammogram',
            value: '2.1 cm spiculated mass lesion in upper outer quadrant of left breast corresponding to palpable mass lesion',
            emphasize: false,
        },
        {
            key: 'Ultrasound',
            value: '2.1 cm irregularly shaped solid mass lesion corresponding to mammographic abnormality; no suspicious axillary lymph nodes seen',
            emphasize: false,
        },
        {
            key: 'Biopsy',
            value: 'Grade 3 invasive ductal carcinoma',
            emphasize: false,
        },
        {
            key: 'Biomarker status',
            value: 'ER/PR&#8288;-&#8288;negative; HER2&#8288;-&#8288;negative',
            emphasize: false,
        },        
    ],
    secondaryCharacteristics: [
        {
            key: 'PET/CT scan',
            value: 'Negative for distant metastases',

        },
        {
            key: 'ECOG PS',
            value: '0',
        },
        {
            key: 'Age',
            value: '49',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Menopausal status',
            value: 'Pre-menopausal',
        },
        {
            key: 'BMI',
            value: '29 kg/m<sup>2</sup>',
        },
        {
            key: 'Family history',
            value: 'No family history of breast cancer',
        },
    ],
}

const pageReferences = [
{
  label: "1."
    , text: "<i>Opdivo</i> [package insert]. Princeton, NJ: Bristol-Meyers Squibb Company; 2023."
} 
,{
  label: "2."
    , text: "<i>Libtayo</i> [package insert]. Tarrytown, NY: Regeneron Pharmaceuticals, Inc.; 2023. "
} 
,{
  label: "3."
    , text: "<i>Jemperli</i> [package insert]. Philadelphia, PA; GlaxoSmithKline LLC; 2023."
} 
,{
  label: "4."
    , text: "<i>Zynyz</i> [package insert]. Wilmington DE; Incyte Corporation; 2023."
} 
,{
    label: "5."
      , text: "<i>Loqtorzi</i>  [package insert]. Redwood City, CA; Coherus BioSciences; 2023."
  } 
,{
  label: "6."
  , text: "Schmid P, Cortes J, Dent R, et al. Pembrolizumab or placebo + chemotherapy followed by pembrolizumab or placebo for early-stage triple-negative breast cancer: updated event-free survival results from the phase 3 KEYNOTE-522 study. Slide deck presented at: European Society for Medical Oncology; October 20, 2023; Madrid, Spain."
},
{
    label: "7.",
    text: "Schmid P, Cortes J, Dent R, et al. Event-free survival with pembrolizumab in early triple-negative breast cancer. <i>N Engl J Med.</i> 2022;386(6):556–567. doi:10.1056/NEJMoa2112651"
},
,{
  label: "8."
    , text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Breast Cancer V.4.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed March 24, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
} 
,{
  label: "9."
    , text: "American Cancer Society. Breast Cancer Signs and Symptoms. https://www.cancer.org/cancer/breast-cancer/about/breast-cancer-signs-and-symptoms.html. Updated January 14, 2022. Accessed September 30, 2022."
} 
];

const footnotesOne = [
    {
        label: "q.",
        text: "Defined as the time from randomization to the data cutoff date of March 23, 2023."
    },
    {
        label: "r.",
        text: "Hazard ratio (CI) analyzed based on a Cox regression model with treatment as a covariate stratified by the randomization stratification factors."
    },
    {
        label: "s.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
];

const footnotesTwo = [
    {
        label: "u.",
        text: "In KEYNOTE&#8288;-&#8288;522, carboplatin/paclitaxel was used followed by AC or EC."
    }
];

const TextBlockFootnotes = [
    {
        label: "k.",
        text: "Defined as the time from randomization to the data cutoff date of March 23, 2023."
    },
    {
        label: "l.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
    {
        label: "m.",
        text: "The sixth prespecified interim analysis of EFS was calendar-driven planned to occur ~72 months after the first participant was randomized."
    },
    {
        label: "n.",
        text: "Hazard ratio (CI) analyzed based on a Cox regression model with treatment as a covariate stratified by the randomization stratification factors."
    },
];

const KMCurveFootnotes = [
    {
        label: "o.",
        text: "Defined as the time from randomization to the data cutoff date of March 23, 2023."
    },
    {
        label: "p.",
        text: "Carboplatin/paclitaxel followed by AC or EC."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                   <PageSection>
                        <AnchorLinks items={anchorLinkModelData}
                        />
                   </PageSection>
                   <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">The First and Only Anti–PD&#8288;-&#8288;1 Indicated as Part of a Complete Regimen for High-Risk Early-Stage TNBC<sup>1–5</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}> 
                            <HRTNBC />
                        </PageSection>
                        <PageSection bgColor="white" title={anchorLinkModelData[1].label}>
                            <PageSubSection title="Pathological Complete Response">
                                <EndpointDisplay title="pCR Rate (ypT0/Tis ypNo)<sup>c</sup>"
                                    limitation="KEYTRUDA + Chemotherapy<sup>d</sup> vs Placebo + Chemotherapy<sup>d</sup> in the Neoadjuvant Setting"
                                    cards={
                                        [
                                            {
                                                dataType: 'pCR',
                                                label: 'KEYTRUDA + chemotherapy<sup>d</sup>',
                                                rate: '63.0%',
                                                ci: '95% CI, 59.5–66.4',
                                                n: '494/784',
                                            },
                                            {
                                                dataType: 'pCR',
                                                label: 'Placebo + chemotherapy<sup>d</sup>',
                                                rate: '55.6%',
                                                ci: '95% CI, 50.6–60.6',
                                                n: '217/390',
                                            }
                                        ]
                                    }
                                    />
                                <SecondaryCallout 
                                    title="Estimated treatment difference: ~7.5% (95% CI, 1.6-13.4)<sup>e,f</sup> with KEYTRUDA + chemotherapy<sup>d</sup> vs placebo + chemotherapy<sup>d</sup>"
                                    alt=""
                                    footnotes={pcrKeySecondaryCalloutFootnotes}
                                    definitions="CI = confidence interval; AC = doxorubicin + cyclophosphamide; EC = epirubicin + cyclophosphamide."
                                />
                            </PageSubSection>
                            <PageSubSection title="Superior EFS With KEYTRUDA + Chemotherapy<sup>g</sup>/KEYTRUDA vs Placebo + Chemotherapy<sup>g</sup>/Placebo">
                                <p>Event-free Survival (N=1,174)</p>
                                <KMCurve 
                                    title="Kaplan-Meier Estimates of EFS in KEYNOTE&#8288;-&#8288;522<sup>h–j</sup>"
                                    limitation=""
                                    hr="HR<sup>h</sup>=0.63; 95% CI, 0.48–0.82; <i>P</i><sup> i,j</sup>=0.00031"
                                    image={() => <StaticImage 
                                        src="../../assets/TNBC-KM-EFS-1-Chart-v4.png" 
                                        placeholder="blurred" 
                                        alt="Kaplan-Meier Estimates of Event-Free Survival in KEYNOTE-522" />} 
                                />

                                <SecondaryCallout 
                                    title="37% reduction in the risk of an EFS event with KEYTRUDA + chemotherapy<sup>g</sup>/KEYTRUDA vs placebo + chemotherapy<sup>g</sup>/placebo (HR<sup>h</sup>=0.63; 95% CI, 0.48–0.82; <i>P</i><sup>i,j</sup>=0.00031)"
                                    alt=""
                                    definitions=""
                                />

                                <SecondaryCallout 
                                    title="The number of patients with an event was 123/784 (16%) with KEYTRUDA + chemotherapy<sup>g</sup>/KEYTRUDA vs 93/390 (24%) with placebo + chemotherapy<sup>g</sup>/placebo"
                                    alt=""
                                    footnotes={keyChemoFootnotes}
                                    definitions="HR = hazard ratio."
                                />
                            </PageSubSection>
                        
                            <PageSubSection title="KEYNOTE&#8288;-&#8288;522: Updated EFS at Protocol-Specified Interim Analysis">
                                <TextBlock>
                                    <h4>Kaplan-Meier Estimates of EFS at Median Duration of Follow-Up<sup>k</sup> of 63.1 Months (53.9–72.0 Months) for Patients Receiving KEYTRUDA + Chemotherapy<sup>l</sup>/KEYTRUDA vs Placebo + Chemotherapy<sup>l</sup>/Placebo<sup>6,m</sup></h4>
                                    <p><b>LIMITATIONS: </b>No formal statistical testing was conducted for the protocol-specified analysis of EFS and, therefore, no conclusions can be drawn.</p>
                                </TextBlock>
                                <KMCurve
                                    image={() => <StaticImage 
                                        src="../../assets/TNBC-KM-EFS-v2.png" 
                                        placeholder="blurred" 
                                        alt="Updated Event-Free Survival at Protocol-Specified Interim Analysis in KEYNOTE-522" />
                                    }
                                />
                                 <TextBlock
                                    footnotes={TextBlockFootnotes}
                                 >
                                        <ul>
                                            <li>The number of patients with an event was 145/784 (18.5%) with KEYTRUDA + chemotherapy<sup>l</sup>/KEYTRUDA vs 108/390 (27.7%) with placebo + chemotherapy<sup>l</sup>/placebo (HR<sup>n</sup>=0.63; 95% CI, 0.49–0.81).</li>
                                            <li>EFS was defined as the time from randomization to the first occurrence of any of the following events: Progression of disease that precludes definitive surgery, local or distant recurrence, second primary malignancy, or death due to any cause.</li>
                                        </ul>
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor='white'>
                            <TextBlock>
                                <h4>EFS at Median Duration of Follow-Up<sup>o</sup> of 63.1 Months (53.9–72.0 Months) for Patients Receiving KEYTRUDA + Chemotherapy<sup>p</sup>/KEYTRUDA vs Placebo + Chemotherapy<sup>p</sup>/Placebo Across Prespecified Subgroups<sup>6</sup></h4>
                                <p><b>LIMITATIONS: </b>KEYNOTE&#8288;-&#8288;522 was not powered to detect differences in the treatment effect in these subgroups, and no statistical testing was planned for this analysis; therefore, no conclusions can be drawn. Results from exploratory subgroup analyses should be interpreted with caution because of the modest patient numbers and potential imbalances in baseline characteristics within the subgroups.</p>
                            </TextBlock>
                            <ForestPlot 
                                title="Subgroup Analysis in KEYNOTE&#8288;-&#8288;522<sup>6</sup>"
                                chartAttribution="For overall population and PD-L1 subgroups, analyses based on Cox regression model with Efron’s method of tie handling with treatment as a covariate and stratified by nodal status (positive vs negative), tumor size (T1/T2 vs T3/T4), and frequency of carboplatin (once weekly vs once every 3 weeks); for other subgroups, analysis based on unstratified Cox model."
                                footnotes={KMCurveFootnotes}
                                image={() => <StaticImage 
                                    src="../../assets/subgroup-chart-tnbc.png" 
                                    placeholder="blurred" 
                                    alt="Subgroup Analysis in KEYNOTE-522" />
                                } 
                                />
                        </PageSection>

                        <PageSection bgColor="cloud">
                            <TextBlock>
                                <h4>Kaplan-Meier Estimates of Distant Progression– or Distant Recurrence–Free Survival at Median Duration of Follow-Up<sup>q</sup> of 63.1 Months in KEYNOTE&#8288;-&#8288;522<sup>6</sup></h4>
                                <p><b>LIMITATIONS: </b>KEYNOTE&#8288;-&#8288;522 was not powered to detect differences in the treatment effect in this analysis, and no statistical testing was planned for this exploratory analysis, and therefore, no conclusions can be drawn. Results from exploratory analyses should be interpreted with caution.</p>
                            </TextBlock>
                            <KMCurve
                                image={() => <StaticImage 
                                    src="../../assets/TNBC-KM-Distant-Chart-v2.png" 
                                    placeholder="blurred" 
                                    alt="Kaplan-Meier Estimates of Distant Progression- or Distant Recurrence Free Survival at Median Duration of Follow-Up of 63.1 months in KEYNOTE522" />} 
                                
                            />
                            <TextBlock
                                footnotes={footnotesOne}
                            >
                                <ul>
                                    <li>The number of patients with an event was 120/784 (15.3%) with KEYTRUDA + chemotherapy<sup>s</sup>/KEYTRUDA vs 90/390 (23.1%) with placebo + chemotherapy<sup>s</sup>/placebo (HR<sup>r</sup>= 0.64; 95% CI, 0.49-0.84).</li>
                                    <li>An exploratory endpoint was distant progression-free or distant recurrence-free survival, which was defined as the time from randomization to an event of distant progression or distant recurrence, as assessed by the investigator, or death due to any cause, whichever occurred first.<sup>7</sup></li>
                                </ul>
                            </TextBlock>
                        </PageSection>

                        <PageSection bgColor="white" title={anchorLinkModelData[2].label}>
                            <NCCNcallout 
                                data={NCCNcalloutData}
                                footnotes={NCCNcalloutFootnotes}
                                definitions={NCCNcalloutDefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Hypothetical Patient Based on Those Studied in KEYNOTE&#8288;-&#8288;522<sup>8,9</sup>">
                                <TextBlock>
                                  Patients Eligible for a Regimen Including KEYTRUDA Regardless of Tumor PD&#8288;-&#8288;L1 Expression
                                </TextBlock>
                                <PatientProfile {...patientProfileCheyanne} definitions="BMI = body mass index; CT = computed tomography; ER = estrogen receptor; HER2 = human epidermal growth factor receptor 2; PET = positron emission tomography; PR = progesterone receptor." />
                                
                                <SecondaryCallout 
                                    title="Start with KEYTRUDA in combination with chemotherapy<sup>u</sup> in the neoadjuvant setting. Then stay with KEYTRUDA as a single agent in the adjuvant setting after surgery."
                                    alt=""
                                    footnotes={footnotesTwo}
                                />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    )
};

export default Page;
